<template>
  <template v-if="isDesktopVersion">
    <arora-nuxt-link
      v-if="actionBanner.NotShowTextOnBanner"
      class="v-banner-info"
      :href="actionBanner.UseLinkOnBanner ? actionBanner.LinkOnBanner : actionBanner.Link"
      :label="actionBanner.Title"
    >
      <arora-nuxt-image
        :alt="actionBanner.Title"
        :image="actionBanner.ImageBigBannerInfo"
        image-type="EventBannerBig"
      />
    </arora-nuxt-link>
    <div v-else>
      <arora-nuxt-image
        :alt="actionBanner.Title"
        :image="actionBanner.ImageBigBannerInfo"
        image-type="EventBannerBig"
      />
      <div
        v-if="!actionBanner.NotShowTextOnBanner"
        class="v-description-action-block v-mobile-action"
      >
        <div
          class="v-title"
          v-html="sanitize(actionBanner.Title)"
        />
        <div
          class="v-teaser"
          v-html="sanitize(actionBanner.Teaser)"
        />
        <arora-nuxt-link
          class="v-btn v-btn-lg v-btn-banner-info"
          :href="
            actionBanner.UseLinkOnBanner ? actionBanner.LinkOnBanner : actionBanner.Link
          "
          :label="translate('bannersPage.moreDetails')"
        />
      </div>
    </div>
  </template>

  <template v-else>
    <div class="v-banner-info">
      <arora-nuxt-link
        class="v-w-100 v-h-100"
        :href="
          actionBanner.UseLinkOnBanner ? actionBanner.LinkOnBanner : actionBanner.Link
        "
        :label="actionBanner.Title"
      >
        <arora-nuxt-image
          :alt="actionBanner.Title"
          :image="actionBanner.ImageSmallEventInfo"
          image-type="EventSmall"
        />
        <div
          v-if="!actionBanner.NotShowTextOnBanner"
          class="v-description-action-block v-mobile-action"
        >
          <div
            class="v-title"
            v-html="sanitize(actionBanner.Title)"
          />
          <div
            class="v-teaser"
            v-html="sanitize(actionBanner.Teaser)"
          />
        </div>
      </arora-nuxt-link>
    </div>
  </template>
</template>

<script setup lang="ts">
import type { Action } from '~types/pageStore'

import { useWindowSize } from '@arora/common'

defineProps<{ actionBanner: Action }>()

const { sanitize, translate } = useI18nSanitized()

const { isDesktopVersion } = useWindowSize()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

#v-banners-main-page__Minas-Anor {
  .v-description-action-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    color: #fff;
    .v-title {
      position: relative;
      z-index: 2;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 8px;
      p {
        margin: 0;
      }
    }

    .v-teaser {
      position: relative;
      z-index: 2;
      font-size: 1.7rem;
      margin-bottom: 35px;

      p {
        margin: 0;
      }
    }

    .v-btn-banner-info {
      border-radius: variables.$BorderRadius;
      color: #250000;
      background: rgba(255, 255, 255, 0.7);
      font-size: 1.2rem;
      font-weight: 500;
    }

    @include mixins.lg {
      &.v-mobile-action {
        top: initial;
        bottom: 6px;
        left: 6px;
        right: 6px;
        transform: initial;
        color: #222;
        padding: 0;
        align-items: start;
        padding: 4px;
        .v-title {
          font-size: 1rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .v-teaser {
          font-size: 0.8rem;
          margin-bottom: 1.75rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.6);
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
    }
  }
}
</style>
